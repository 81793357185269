import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

function Title({ children, level, size, className }) {
  return (
    <StyledTitle as={`h${level}`} size={size} className={className}>
      {children}
    </StyledTitle>
  )
}

const StyledTitleM = css`
  font-size: 26px;
  line-height: 29px;
`
const StyledTitleS = css`
  font-size: 22px;
  line-height: 25px;
`

const StyledTitle = styled.h1`
  margin-top: 0;
  margin-bottom: 0;
  font-style: normal;
  font-weight: 500;

  color: ${(props) => props.theme.colorSecondary};

  ${(props) => props.size === 'm' && StyledTitleM}
  ${(props) => props.size === 's' && StyledTitleS}
`

Title.defaultProps = {
  level: 2,
  size: 'm',
  className: '',
}

Title.propTypes = {
  children: PropTypes.node.isRequired,
  level: PropTypes.number,
  size: PropTypes.string,
  className: PropTypes.string,
}

export default Title
