import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import ellipsis from 'polished/lib/mixins/ellipsis'
import { useSelector } from 'react-redux'
import IconCart from '../../../../assets/icons/cart.svg?svg-sprite'
import NextLink from '../../../../components/Next/NextLink'
import cart from '../../cartSlice'
import useNumber from '../../../../hooks/useNumber'
import user from '../../../User/userSlice'

function CartPreviewButton({ variant }) {
  const { cartMini } = useSelector(cart.getCartMini)
  const all = cartMini?.all
  const { currency } = useSelector(user.getCurrency)

  const amount = all?.sum_with_nds
    ? `${useNumber(all.sum_with_nds)} ${currency}`
    : `0 ${currency}`
  const count = all?.goods_count
  const isEmpty = !count
  const width = `${Math.floor((1 - 1 / (count + 1 / 2)) * 100)}%`

  return (
    <NextLink href="/cart">
      <StyledCartPreviewBtn width={width} variant={variant} notEmpty={!isEmpty}>
        <StyledCartPreviewBtnInner>
          <StyledCartPreviewBtnIcon>
            <IconCart width={27} height={32} />
          </StyledCartPreviewBtnIcon>
          <StyledCartPreviewBtnText>
            {isEmpty || variant === 'full' ? 'Корзина' : amount}
          </StyledCartPreviewBtnText>
          {!isEmpty && (
            <StyledCartPreviewBtnCount>{count}</StyledCartPreviewBtnCount>
          )}
        </StyledCartPreviewBtnInner>
      </StyledCartPreviewBtn>
    </NextLink>
  )
}

CartPreviewButton.defaultProps = {
  variant: 'mini',
}

CartPreviewButton.propTypes = {
  variant: PropTypes.string,
}

const StyledCartPreviewBtnInner = styled.div`
  display: inline-flex;
  flex-wrap: nowrap;
  max-width: 100%;
  align-items: center;
`

const StyledCartPreviewBtnIcon = styled.div`
  font-size: 0;
  line-height: 0;
  flex: 0 0 auto;
  margin-right: 3px;
`

const StyledCartPreviewBtnText = styled.div`
  ${ellipsis('100%')};
  font-size: 16px;
  line-height: 19px;
`

const StyledCartPreviewBtnCount = styled.div`
  color: ${(props) => props.theme.white};
  background-color: ${(props) => props.theme.azure};
  font-weight: 700;
  font-size: 11px;
  line-height: 13px;
  flex: 0 0 auto;
  min-width: 22px;
  height: 17px;
  border-radius: ${(props) => props.theme.borderRadius};
  margin-left: 7px;
  padding: 2px 5px;
  text-align: center;
`

const StyledCartPreviewNotEmptyFull = css`
  position: relative;
  background-color: ${(props) => props.theme.white};
  border-color: ${(props) => props.theme.azure};
  overflow: hidden;

  ${StyledCartPreviewBtnInner} {
    position: relative;
    z-index: 1;
  }

  &::before {
    transition: width ${(props) => props.theme.transitionTime};
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    content: '';
    background: #dff8f7;
    width: ${(props) => props.width};
  }

  &:hover {
    border-color: ${(props) => props.theme.azureLight};
  }
`
const StyledCartPreviewFull = css`
  border: 1px solid #b7c6d4;
  border-radius: 48px;
  height: 48px;
  padding-left: 24px;
  padding-right: 24px;
  color: ${(props) => props.theme.colorSecondary};
  transition: border-color ${(props) => props.theme.transitionTime};

  &:hover {
    color: ${(props) => props.theme.colorSecondary};
    border-color: ${(props) => props.theme.blue};
  }

  ${StyledCartPreviewBtnCount} {
    font-size: 12px;
    line-height: 15px;
    min-width: 25px;
    height: 19px;
  }

  ${StyledCartPreviewBtnText} {
    font-size: 18px;
    line-height: 21px;
  }

  ${StyledCartPreviewBtnIcon} {
    margin-left: -3px;
    svg {
      width: 33px;
      height: 35px;
    }
  }
`

const StyledCartPreviewHeader = css`
  ${StyledCartPreviewBtnText} {
    display: none;
  }

  ${StyledCartPreviewBtnCount} {
    margin-left: 0;
  }

  ${StyledCartPreviewBtnIcon} {
    margin-right: 0;
    svg {
      width: 32px;
      height: 35px;
    }
  }
`

const StyledCartPreviewBtn = styled.a`
  text-decoration: none;
  color: ${(props) => props.theme.blue};
  max-width: 100%;
  transition: color ${(props) => props.theme.transitionTime};
  display: inline-flex;
  &.next-link-active {
    pointer-events: none;
  }

  &:hover {
    color: ${(props) => props.theme.blueLight};
  }

  ${(props) => props.variant === 'header' && StyledCartPreviewHeader}
  ${(props) => props.variant === 'full' && StyledCartPreviewFull}
  ${(props) =>
    props.notEmpty && props.variant === 'full' && StyledCartPreviewNotEmptyFull}
`

export default CartPreviewButton
