import { createSlice, createSelector } from '@reduxjs/toolkit'
import qs from 'qs'
import { loggedIn, loggedOut } from '../Auth/authSlice'
import removeEmpty from '../../services/helpers/removeEmpty'

const ordersSlice = createSlice({
  name: 'orders',
  initialState: {
    ordersHistory: {},
    ordersStatuses: [],
    ordersFinance: {},
    orderParams: null,
    lastCompletedOrder: null,
    lastRepeatedOrder: null,
    firstOrderDate: null,
  },
  reducers: {
    ordersHistoryAdded(state, { payload: { queryString, ordersHistory } }) {
      state.ordersHistory[queryString] = ordersHistory
    },
    ordersHistoryCleared(state) {
      state.ordersHistory = {}
    },
    ordersStatusesAdded(state, { payload: { ordersStatuses } }) {
      state.ordersStatuses = ordersStatuses
    },
    ordersFinanceAdded(state, { payload: { queryString, ordersFinance } }) {
      state.ordersFinance[queryString] = ordersFinance
    },
    orderCompleted(state, { payload: { order } }) {
      state.lastCompletedOrder = order
    },
    orderRepeated(state, { payload: { cart } }) {
      state.lastRepeatedOrder = cart
    },
    firstOrderDateAdded(state, { payload: { date } }) {
      state.firstOrderDate = date
    },
  },
  extraReducers: {
    [loggedIn]: (state, { payload: { user } }) => {
      state.orderParams = user?.order_params
    },
    [loggedOut]: (state) => {
      state.ordersHistory = {}
      state.ordersStatuses = []
      state.ordersFinance = {}
      state.orderParams = null
      state.lastCompletedOrder = null
      state.firstOrderDate = null
    },
  },
})

const getOrderHistory = (query) => (state) => {
  return state.orders.ordersHistory[query]
}

const getOrderFinance = (query) => (state) => {
  return state.orders.ordersFinance[query]
}

const getFirstOrderDate = (state) => {
  return state.orders.firstOrderDate
}

const fetchOrdersFinance = (query = {}) => async (
  dispatch,
  getState,
  { api },
) => {
  const formattedQuery = removeEmpty({
    per_page: 20,
    ...query,
  })
  const queryString = qs.stringify(formattedQuery)
  const cashedOrdersFinance = getOrderFinance(queryString)(getState())

  if (cashedOrdersFinance) {
    return cashedOrdersFinance
  }

  const response = await api.order.finance(formattedQuery)
  const ordersFinance = response.data

  dispatch(
    ordersSlice.actions.ordersFinanceAdded({ queryString, ordersFinance }),
  )

  return ordersFinance
}

const fetchOrdersHistory = (query = {}) => async (
  dispatch,
  getState,
  { api },
) => {
  const formattedQuery = removeEmpty({
    per_page: 8,
    ...query,
  })
  const queryString = qs.stringify(formattedQuery)

  const cashedOrdersHistory = getOrderHistory(queryString)(getState())

  if (cashedOrdersHistory) {
    return cashedOrdersHistory
  }

  const response = await api.order.history(formattedQuery)
  const ordersHistory = response.data.orders
  const ordersStatuses = response.data.status_list
  const ordersFirstDate = response.data.first_order_date

  dispatch(
    ordersSlice.actions.ordersHistoryAdded({ queryString, ordersHistory }),
  )
  dispatch(ordersSlice.actions.firstOrderDateAdded({ date: ordersFirstDate }))
  dispatch(ordersSlice.actions.ordersStatusesAdded({ ordersStatuses }))

  return ordersHistory
}

const setOrder = (data) => async (dispatch, getState, { api }) => {
  try {
    const response = await api.order.send(data)
    dispatch(ordersSlice.actions.ordersHistoryCleared())
    dispatch(ordersSlice.actions.orderCompleted({ order: response.data }))

    return response?.data || false
  } catch (e) {
    return false
  }
}

const actReconciliationRequest = () => async (dispatch, getState, { api }) => {
  const result = await api.order.actReconciliationRequest()

  return result.status === 'success'
}

const orderRepeat = (data) => async (dispatch, getState, { api }) => {
  const result = await api.order.repeat(data)
  const isSuccess = result.status === 'success'

  if (isSuccess) {
    dispatch(ordersSlice.actions.orderRepeated({ cart: result.data }))
  }

  return isSuccess
}

const orderCancel = (data) => async (dispatch, getState, { api }) => {
  const result = await api.order.orderCancellation(data)
  const isSuccess = result.status === 'success'

  if (isSuccess) {
    dispatch(ordersSlice.actions.ordersHistoryCleared())
  }
  return isSuccess
}

const getOrderParams = createSelector(
  (state) => state.orders,
  (orders) => {
    const { orderParams } = orders
    return {
      orderParams,
    }
  },
)

const getOrdersStatuses = createSelector(
  (state) => state.orders,
  (orders) => {
    const { ordersStatuses } = orders

    return {
      ordersStatuses,
    }
  },
)

export const {
  ordersHistoryAdded,
  ordersHistoryCleared,
  ordersStatusesAdded,
  orderCompleted,
  orderRepeated,
} = ordersSlice.actions

export default {
  orderCancel,
  orderRepeat,
  actReconciliationRequest,
  fetchOrdersHistory,
  fetchOrdersFinance,
  getFirstOrderDate,
  getOrderParams,
  getOrdersStatuses,
  setOrder,
  reducer: ordersSlice.reducer,
}
