import { createSlice } from '@reduxjs/toolkit'
import { loggedIn, loggedOut } from '../Auth/authSlice'

const favoritesSlice = createSlice({
  name: 'favorites',
  initialState: {
    data: {},
  },
  reducers: {
    favoritesChanged(state, { payload: { favorites } }) {
      state.data = favorites
    },
  },
  extraReducers: {
    [loggedIn]: (state, { payload: { user } }) => {
      state.data = user.favorites
    },
    [loggedOut]: (state) => {
      state.data = {}
    },
  },
})

const favoritesChange = (data) => async (dispatch, getState, { api }) => {
  const response = await api.favorites.change(data)

  dispatch(
    favoritesSlice.actions.favoritesChanged({
      favorites: response.data.summary,
    }),
  )

  return response.status === 'success'
}

const checkFavorites = (state) => (guid) => {
  const favoritesIds = state.favorites?.data?.goods_guids || []

  return !!favoritesIds.find((item) => item === guid)
}

export const { favoritesChanged } = favoritesSlice.actions

export default {
  checkFavorites,
  favoritesChange,
  reducer: favoritesSlice.reducer,
}
