import { createSlice, createSelector } from '@reduxjs/toolkit'
import auth, { loggedIn, loggedOut } from '../Auth/authSlice'
import { orderCompleted, orderRepeated } from '../Orders/OrdersSlice'

const cartSlice = createSlice({
  name: 'cart',
  initialState: {
    cartMini: null,
    cartFull: null,
  },
  reducers: {
    cartProductChanged(state, { payload: { cart } }) {
      state.cartMini = cart
    },
    cartAdded(state, { payload: { cart } }) {
      state.cartFull = cart
    },
  },
  extraReducers: {
    [loggedIn]: (state, { payload: { user } }) => {
      state.cartMini = user?.cart || null
    },
    [loggedOut]: (state) => {
      state.cartMini = null
      state.cartFull = null
    },
    [orderCompleted]: (state, { payload: { order } }) => {
      state.cartMini = order.cart_summary
    },
    [orderRepeated]: (state, { payload: { cart } }) => {
      state.cartMini = cart
    },
  },
})

const fetchCart = (updateUser = false) => async (
  dispatch,
  getState,
  { api },
) => {
  const response = await api.cart.show()

  if (updateUser) {
    await dispatch(auth.updateUser())
  }

  dispatch(cartSlice.actions.cartAdded({ cart: response.data }))
}

const getCountByGuid = (state) => (guid) => {
  const productsIds = state.cart.cartMini?.all?.items || []
  return productsIds[guid]
}

const cartProductChange = (data) => async (dispatch, getState, { api }) => {
  const response = await api.cart.change(data)

  dispatch(cartSlice.actions.cartProductChanged({ cart: response.data }))

  return response.status === 'success'
}

const cartProductRemove = (data) => async (dispatch, getState, { api }) => {
  const response = await api.cart.remove(data)

  dispatch(cartSlice.actions.cartProductChanged({ cart: response.data }))

  return response.status === 'success'
}

const getCartMini = createSelector(
  (state) => state.cart,
  (cart) => {
    const { cartMini } = cart
    return {
      cartMini,
    }
  },
)

const getCartFull = createSelector(
  (state) => state.cart,
  (cart) => {
    const { cartFull } = cart
    return {
      cartFull,
    }
  },
)

export const { cartProductChanged, cartProductRemoved } = cartSlice.actions

export default {
  getCartMini,
  getCartFull,
  getCountByGuid,
  cartProductChange,
  cartProductRemove,
  fetchCart,
  reducer: cartSlice.reducer,
}
