import numeral from 'numeral'

// Я знаю, что это не хук, исправлю)

function useNumber(value, decimals = 2) {
  if (value === null || value === undefined) return 0

  const deciamlsString = ''.padStart(decimals, '0')
  return numeral(value).format(`0,0.${deciamlsString}`).replace(/,/g, ' ')
}

export default useNumber
